html {
  background: url("./assets/Galaxy.png") no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  background-color: #161616;
}

/** This is a total hack. Short-term, can't figure out how to get the toast container to full height */
.recipe-toaster {
  position: fixed !important;
}
